import React from 'react';
import log from '../utils/log';

/**
 * Video Component.
 */
export default class Video extends React.Component {

	/**
	 * Video Object reference.
	 *
	 * @type {Video|null}
	 */
	videoRef = null;

	/**
	 * Constructor.
	 *
	 * @param props
	 * @param context
	 */
	constructor(props, context) {
		super(props, context);
		this.videoRef = React.createRef();

		this.trackTime = this.trackTime.bind(this);
	}

	/**
	 * Add video event listeners.
	 */
	componentDidMount() {
		this.videoRef.current.addEventListener('timeupdate', this.trackTime);
		this.play();
	}

	/**
	 * @param prevProps
	 * @param prevState
	 * @param snapshot
	 */
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.stopAt !== this.props.stopAt || prevProps.startAt !== this.props.startAt) {
			this.play();
		}
	}

	/**
	 * Cleanup.
	 */
	componentWillUnmount() {
		this.videoRef.current.removeEventListener('timeupdate', this.trackTime);
	}

	/**
	 * Play video.
	 */
	play() {
		if (Math.abs(this.videoRef.current.currentTime - this.props.startAt) > 1) {
			this.videoRef.current.currentTime = this.props.startAt;
		}

		this.videoRef.current.play()
			.catch(e => log.warning(e));
	}

	/**
	 * @return {*}
	 */
	render() {
		return <div className="video-box">
			<div className="video">
				<video ref={this.videoRef} src={this.props.src}/>
				{this.props.caption &&
				<div className="video-caption">
					<div className="title">
						{this.props.caption}
					</div>
				</div>
				}
			</div>
		</div>
	}

	/**
	 * Stop at the specific time.
	 */
	trackTime() {
		if (this.props.stopAt > 0 && this.videoRef.current.currentTime >= this.props.stopAt) {
			try {
				this.videoRef.current.pause();
			} catch (e) {
				log.warning(e);
			}
		}
	}
}