import React from 'react';

/**
 * Answer AnswerOption Component.
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default function AnswerOption(props) {
	return <div className="option">
		<div className="option-inner">
			<div className="option-text">{props.text}</div>
		</div>
	</div>
}