import React from 'react';
import AnswerOption from './AnswerOption';
import gsap from 'gsap';

/**
 * Answer AnswerOption List Component.
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default class AnswerOptionList extends React.Component {

	/**
	 * Array of refs to the options.
	 */
	options;

	/**
	 * Show questions.
	 */
	componentDidMount() {
		if (this.props.opened) {
			this.open();
		}
	}

	/**
	 * Show / hide questions.
	 *
	 * @param prevProps
	 * @param prevState
	 * @param snapshot
	 */
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.opened && !prevProps.opened) {
			this.open();
		} else if (!this.props.opened && prevProps.opened && this.tween) {
			this.close();
		}
	}

	/**
	 * Cleanup.
	 */
	componentWillUnmount() {
		if (this.tween) {
			this.tween.kill();
			delete this.tween;
		}
	}

	/**
	 * Show questions.
	 */
	open() {
		this.tween = gsap.to(this.options.querySelectorAll('.option'), {
			duration: 0.7,
			ease: 'power4.inOut',
			stagger: 0.05,
			translateY: '0'
		});
	}

	/**
	 * Hide questions.
	 */
	close() {
		this.tween.reverse();
	}

	/**
	 * @return {*}
	 */
	render() {

		if (!this.props.options) {
			return '';
		}

		this.options = [];

		const options = [];
		this.props.options.forEach((option) => {
			options.push(<AnswerOption key={option.id} text={option.text}/>)
		});

		return <div className="answer-options" ref={el => {
			if (el) {
				this.options = el;
			}
		}}>
			<div className="answer-options-inner">{options}</div>
		</div>
	}
}