import React from 'react';
import Video from '../components/Video';
import AnswerOptionList from '../components/AnswerOptionList';
import ThemeContext from '../theme-context';

// Step constants.
export const STEP_QUESTION = 'question';
export const STEP_COUNT_DOWN = 'count-down';
export const STEP_ANSWER = 'answer';
export const STEP_SCORES = 'scores';

/**
 * Question Block.
 */
export default class Question extends React.Component {

	/**
	 * Render Block.
	 */
	render() {

		const theme = this.context;
		const StatusBox = theme.StatusBox;
		const CountDown = theme.CountDown;

		const step = this.props.step;
		const question = this.props.question;

		let statusBoxStatus, statusBoxText;
		if (step === STEP_ANSWER) {
			statusBoxStatus = 'success';
			statusBoxText = question.answerOptions.find(el => el.id === question.answerId).text;
		} else {
			statusBoxStatus = 'info';
			statusBoxText = question.question
		}

		return <>

			{/* Top Text Box */}
			<StatusBox status={statusBoxStatus}>{statusBoxText}</StatusBox>

			{/* Video */}
			<Video src={question.videoSrc}
				   startAt={step === STEP_ANSWER ? question.videoQuestionLength : 0}
				   stopAt={step === STEP_ANSWER ? 0 : question.videoQuestionLength}
				   caption={question.movie ? `${question.movie.name}, ${question.movie.year} / ${question.movie.company}` : null}
			/>

			{/* AnswerOptions */}
			<AnswerOptionList
				opened={step === STEP_COUNT_DOWN}
				options={question.answerOptions}
			/>
			
			{/* Count Down*/}
			{step === STEP_COUNT_DOWN && <CountDown seconds={this.props.countDownTime}></CountDown>}
		</>
	}
}
Question.contextType = ThemeContext;