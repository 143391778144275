import React from 'react';
import gsap from 'gsap';

/**
 * Logo Component.
 */
export default class Logo extends React.Component {

	chars = [];

	/**
	 * Constructor.
	 *
	 * @param props
	 * @param context
	 * @param chars
	 */
	constructor(props, context, chars) {
		super(props, context);
	}

	/**
	 * Animate characters.
	 */
	animateChars() {
		this.chars.forEach((el, index) => {
			if (el.classList.contains('whitespace')) {
				return;
			}
			this.animateChar(el, 1);
		});
	}

	/**
	 * Animate one random character.
	 */
	animateChar(el, factor) {
		const rotateZ = factor * (-20 + (Math.random() * 40));
		factor = rotateZ < 0 ? 1 : -1;
		gsap.set(el, {rotateZ: 0});
		gsap.timeline()
			.to(el, {
				duration: 2 + (Math.random() * 2),
				ease: 'none',
				rotateZ: rotateZ,
				repeat: -1,
				yoyo: true,
				onComplete: () => this.animateChar(el, factor)
			});
	}

	/**
	 * Start the animation.
	 */
	componentDidMount() {
		this.animateChars();
	}

	/**
	 * Print a text split into characters.
	 *
	 * @param str
	 * @param index
	 * @return {*}
	 */
	printLine(str, index) {
		return <div className={'logo-line' + index}>
			{[...str].map(
				(char, index) => {
					const class_name = ' ' === char ? 'whitespace' : '';
					return <span className={class_name} key={char + index}
								 ref={(el) => el && this.chars.push(el)}>{char}</span>
				}
			)}
		</div>
	}

	/**
	 * @return {*}
	 */
	render() {
		this.chars = [];
		return <div className="quiz-logo">
			{this.printLine('StreamSmart.io', 3)}
			{this.printLine('Harry Potter', 1)}
			{this.printLine('CHAT QUIZ', 2)}
		</div>
	}
}