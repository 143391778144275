import log from '../utils/log';

/**
 * Background music.
 */
export default class BackgroundMusic {
	
	audio;
	playlist;
	recentlyPlayed = [];
	playing = false;
	
	/**
	 * Constructor.
	 *
	 * @param playlist
	 * @param volume
	 */
	constructor(playlist, volume) {
		this.playlist = playlist;
		this.volume = volume;
		
		this.audio = new Audio();
		this.audio.volume = this.volume;
		this.audio.addEventListener('ended', () => this.playNext());
		
		this.init = this.init.bind(this);
		document.addEventListener('click', this.init);
		this.init();
	}
	
	/**
	 * Get next random track.
	 */
	getNextTrack() {
		let index;
		let maxLength = Math.floor(this.playlist.length * 0.5);
		
		do {
			index = Math.floor(Math.random() * this.playlist.length);
		} while (this.recentlyPlayed.indexOf(index) !== -1);
		
		this.recentlyPlayed.unshift(index);
		if (this.recentlyPlayed.length > maxLength) {
			this.recentlyPlayed = this.recentlyPlayed.slice(0, maxLength);
		}
		
		return this.playlist[index];
	}
	
	/**
	 * Try to autoplay audio. Fallback to click.
	 */
	init() {
		(async () => {
			await this.playNext();
			if (this.playing) {
				document.removeEventListener('click', this.init);
			}
		})();
	}
	
	/**
	 * Start playing music.
	 */
	async playNext() {
		try {
			let track = this.getNextTrack();
			log.debug('Music Track', track);
			
			this.audio.src = track;
			await this.audio.play();
			this.playing = true;
		} catch (e) {
			log.warning(e);
		}
	}
}
