import React from 'react';

/**
 * Status Box Component.
 */
export default class StatusBox extends React.Component {

	/**
	 * @return {*}
	 */
	render() {
		return <div className={`status-box ${this.props.status}`}>
			<div className="status-box-inner">
				<div id="progress-bar" className="progress-bar"></div>
				<div className="status">
					<div className="status-inner">
						<div className="status-text">{this.props.children}</div>
					</div>
				</div>
			</div>
		</div>
	}
}