let video = document.createElement('video');
video.preload = 'auto';

/**
 * Preload video.
 *
 * @param src
 */
export default function preloadVideo(src) {
	video.src = src;
}