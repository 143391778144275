import UserScore from '../components/UserScore';
import React from 'react';
import ThemeContext from '../theme-context';
import gsap from "gsap";

/**
 * User Leaderboard.
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default class Leaderboard extends React.Component {

	maxItems = 9; // Maximum number of users to show.

	/**
	 * Constructor.
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);
		this.scoresRef = React.createRef();
	}

	/**
	 * Animate user rows.
	 */
	componentDidMount() {
		const $userScore = this.scoresRef.current.querySelectorAll('.user-score');
		const inTime = 0.6;
		const outTime = 0.9;
		const stagger = 0.1;
		const inTimeAll = inTime + stagger * $userScore.length;
		const outTimeAll = outTime + stagger * $userScore.length;
		const outDelay = +this.props.ttl - inTimeAll - outTimeAll + 0.3;

		this.tl = gsap.timeline()
			.from($userScore,
				{
					duration: inTime,
					ease: 'back.out',
					stagger: stagger,
					translateY: '200%',
					translateX: '10%',
					opacity: 0
				})
			.to($userScore,
				{
					duration: outTime,
					ease: 'back.inOut(1.7)',
					stagger: stagger,
					translateX: '100px',
					opacity: 0,
					delay: outDelay
				});
	}

	/**
	 * Clean up.
	 */
	componentWillUnmount() {
		if (this.tl) {
			this.tl.kill();
		}
	}

	/**
	 * @return {*}
	 */
	render() {

		const StatusBox = this.context.StatusBox;

		const title = this.context.leaderBoardTitle || 'Best Players of the Week';
		const scores = this.props.scores;

		let numItems = 0;
		let list = [];
		scores.forEach(row => {
			if (numItems++ < this.maxItems) {
				list.push(<UserScore key={row.user.id} user={row.user} scores={row.scores}/>)
			}
		});

		return <>
			<StatusBox status={'normal'}>{title}</StatusBox>
			<div className="leaderboard">
				<div className="best-scores" ref={this.scoresRef}>{list}</div>
			</div>
		</>
	}
}
Leaderboard.contextType = ThemeContext;
