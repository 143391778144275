import React from 'react';

/**
 * User score component.
 */
export default class UserScore extends React.Component {

	/**
	 * @return {React.ReactNode}
	 */
	render() {
		const user = this.props.user;
		const points = parseInt(this.props.scores);

		return <div className="user-score">
			<div className="user-score-inner">
				<span className="user-avatar"><img src={user.profileImageUrl} alt=""/></span>
				<span className="user-name">{user.displayName}</span>
				<span className="user-points">{points} point{points > 1 ? 's' : ''}</span>
			</div>
		</div>
	}
}