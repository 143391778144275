import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './js/App';
import io from 'socket.io-client';

const serverUrl = new URLSearchParams(document.location.search).get('server') || process.env.REACT_APP_SERVER_URL;

const socket = io(serverUrl, {
	transports: ['websocket']
});

socket.on('connect_error', (err) => {
	console.log(`connect_error due to ${err.message}`);
});

window.socket = socket;

// Init app.
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);