import React from 'react';

import '../css/App.scss';
import ThemeContext from './theme-context';
import MainLayout from './layouts/MainLayout';

import currentTheme from '../themes/harry-potter/main';

/**
 * App class.
 */
export default class App extends React.Component {

	/**
	 * Render screen.
	 *
	 * @return {*}
	 */
	render() {
		return <ThemeContext.Provider value={currentTheme}>
			<MainLayout/>
		</ThemeContext.Provider>
	}
}
App.contextType = ThemeContext;