import UserScore from '../components/UserScore';
import React from 'react';
import gsap from 'gsap';
import ThemeContext from '../theme-context';
import log from '../utils/log';

import cheerSound from '../../assets/cheering.mp3';

/**
 * A list of people who answered right.
 */
export default class Scores extends React.Component {
	
	maxItems = 10; // Maximum number of users to show.
	
	/**
	 * Constructor.
	 *
	 * @param props
	 */
	constructor(props) {
		super(props);
		
		this.failTextRef = React.createRef();
		this.failImageRef = React.createRef();
		this.scoresRef = React.createRef();
	}
	
	/**
	 * Init animations.
	 */
	componentDidMount() {
		if (this.props.scores && this.props.scores.length) {
			this.animateWinners();
		} else {
			this.animateEmptyScores();
		}
	}
	
	/**
	 * Animated list of winners.
	 */
	animateWinners() {
		
		// Sounds.
		this.audio = new Audio(cheerSound);
		this.audio.volume = 0.55;
		
		(async () => {
			try {
				await this.audio.play();
			} catch (e) {
				log.warning(e);
			}
		})();
		
		// Animations.
		const $userScore = this.scoresRef.current.querySelectorAll('.user-score');
		const inTime = 0.6;
		const outTime = 0.9;
		const stagger = 0.1;
		const inTimeAll = inTime + stagger * $userScore.length;
		const outTimeAll = outTime + stagger * $userScore.length;
		const outDelay = +this.props.ttl - inTimeAll - outTimeAll + 0.3;
		
		this.tl = gsap.timeline()
			.from($userScore,
				{
					duration: inTime,
					ease: 'back.out',
					stagger: stagger,
					translateY: '200%',
					translateX: '10%',
					opacity: 0
				})
			.to($userScore,
				{
					duration: outTime,
					ease: 'back.inOut(1.7)',
					stagger: stagger,
					translateX: '100px',
					opacity: 0,
					delay: outDelay
				});
	}
	
	/**
	 * Animate screen without winners.
	 */
	animateEmptyScores() {
		
		const imgShowDelay = 0.3;
		const imgShowDuration = 1.8;
		const imgShowTime = +this.props.ttl / 2 - imgShowDuration;
		const imgHideDuration = 1.2;
		const yTo = 175;
		
		this.tl = gsap.timeline()
			.to(this.failImageRef.current, {
				duration: imgShowDuration,
				translateY: 0,
				ease: 'expo.out',
				delay: imgShowDelay,
			})
			.to(this.failImageRef.current, {
				duration: imgHideDuration,
				translateY: yTo,
				ease: 'power3.inOut',
				delay: imgShowTime
			})
			.to(this.failTextRef.current, {
				duration: imgHideDuration,
				opacity: 1,
				ease: 'power2.in',
				delay: -imgHideDuration
			});
	}
	
	/**
	 * Cleanup.
	 */
	componentWillUnmount() {
		if (this.tl) {
			this.tl.kill();
			delete this.tl;
		}
		if (this.audio) {
			try {
				this.audio.pause();
				delete this.audio;
			} catch (e) {
				log.warning(e);
			}
		}
	}
	
	/**
	 * @return {*}
	 */
	render() {
		
		const StatusBox = this.context.StatusBox;
		
		let numItems = 0;
		let list = [];
		this.props.scores.forEach(row => {
			if (numItems++ < this.maxItems) {
				list.push(<UserScore key={row.user.id} user={row.user} scores={row.scores}/>);
			}
		});
		
		let status, text;
		if (list.length) {
			status = 'success';
			text = 'Winners!';
		} else {
			status = 'fail';
			text = 'No one answered correctly';
		}
		
		return <>
			{/* Top Text Box */}
			<StatusBox status={status}>{text}</StatusBox>
			<div className={`score-box status-${status}`} ref={this.scoresRef}>
				<div className="winners">
					{list.length
						? list
						: <div className="fail">
							<div ref={this.failTextRef} className="fail-text">
								To earn points<br/>
								Type your answer in the chat
							</div>
							<div ref={this.failImageRef} className="fail-image">
								<div className="fix-bg-cache"></div>
							</div>
						</div>
					}
				</div>
			</div>
		</>
	}
}
Scores.contextType = ThemeContext;
