import React from 'react';
import gsap from 'gsap';
import file from '../sounds/countdown.mp3';
import log from "../../../js/utils/log";

/**
 * Preloader line count down.
 */
export default class CountDown extends React.Component {

	/**
	 * Start animation.
	 */
	componentDidMount() {
		const preloader = document.getElementById('progress-bar');
		if (preloader) {
			this.anim = gsap.to(preloader, {
				duration: this.props.seconds,
				ease: 'linear',
				width: '100%',
				background: '#ff0000',
				opacity: 0.8,
			});
		}
		
		(async () => {
			this.audio = new Audio(file);
			this.audio.volume = 0.5;
			try {
				await this.audio.play();
			} catch (e) {
				log.warning(e);
			}
		})();
	}

	/**
	 * Cleanup.
	 */
	componentWillUnmount() {
		if (this.anim) {
			this.anim.kill();
		}
		try {
			this.audio.pause();
		} catch (e) {
			log.warning(e);
		}
	}

	/**
	 * Element has no body.
	 *
	 * @return {*}
	 */
	render() {
		return null;
	}
}
