import Question from '../../js/blocks/Question';
import Leaderboard from '../../js/blocks/Leaderboard';
import Logo from './components/Logo';
import Scores from '../../js/blocks/Scores';
import StatusBox from './components/StatusBox'
import CountDown from './components/CountDown';

import './style.scss';

import track1 from './sounds/background/news-room-news.mp3';
import track2 from './sounds/background/official-national-anthem.mp3';
import track3 from './sounds/background/english-country-garden.mp3';
import track4 from './sounds/background/happy-haunts.mp3';
import track5 from './sounds/background/rolling-hills.mp3';
import track6 from './sounds/background/saving-the-world.mp3';
import track7 from './sounds/background/the-story-unfolds.mp3';

export default {

	// Settings.
	name: 'harry-potter',
	leaderBoardTitle: 'Best Players of the Week',
	musicTracks: [
		track1,
		track2,
		track3,
		track4,
		track5,
		track6,
		track7
	],
	musicVolume: 0.5,

	// Components.
	CountDown: CountDown,
	Question: Question,
	Leaderboard: Leaderboard,
	Logo: Logo,
	Scores: Scores,
	StatusBox: StatusBox
};
