const levels = {
	'debug': 0,
	'info': 1,
	'warning': 2,
	'error': 3
};

const doLog = new URLSearchParams(document.location.search).get('debug') || process.env.REACT_APP_DEBUG === 'true';
const logLevel = doLog ? 0 : 100;

/**
 * Log message.
 * @param level
 * @param msg
 */
function log(level) {
	if (level >= logLevel) {
		let args = [...arguments].slice(1);
		console.log(...args);
	}
}

export default {
	debug: function () {
		log(levels.debug, '[DEBUG]', ...arguments);
	},
	info: function () {
		log(levels.info, '[INFO]', ...arguments);
	},
	warning: function () {
		log(levels.warning, '[WARNING]', ...arguments);
	},
	error: function () {
		log(levels.error, '[ERROR]', ...arguments);
	}
};