import React from 'react';
import ThemeContext from '../theme-context';
import BackgroundMusic from '../components/BackgroundMusic';
import Leaderboard from '../blocks/Leaderboard';
import log from '../utils/log';
import preloadVideo from "../utils/preload";
import {STEP_ANSWER, STEP_COUNT_DOWN, STEP_QUESTION, STEP_SCORES} from "../blocks/Question";

/**
 * App class.
 */
export default class MainLayout extends React.Component {

	/**
	 * @param props
	 * @param context
	 */
	constructor(props, context) {
		super(props, context);

		this.state = {
			screen: '',
			weeklyScores: [],
			scores: []
		};

		this.screenLeaderboard = this.screenLeaderboard.bind(this);

		this.preloadQuestion = this.preloadQuestion.bind(this);
		this.setQuestion = this.setQuestion.bind(this);
		this.stepQuestion = this.stepQuestion.bind(this);
		this.stepCountDown = this.stepCountDown.bind(this);
		this.stepAnswer = this.stepAnswer.bind(this);
		this.stepScores = this.stepScores.bind(this);
	}

	/**
	 * Once component is mounted.
	 */
	componentDidMount() {
		if (this.context.musicTracks) {
			new BackgroundMusic(this.context.musicTracks, this.context.musicVolume);
		}

		const socket = window.socket;
		socket.on('screen-leaderboard', this.screenLeaderboard);
		socket.on('question', this.setQuestion);
		socket.on('next-question', this.preloadQuestion);
		socket.on('step-question', this.stepQuestion);
		socket.on('step-count-down', this.stepCountDown);
		socket.on('step-answer', this.stepAnswer);
		socket.on('step-scores', this.stepScores);
	}

	/**
	 * Once component is about to unmount.
	 */
	componentWillUnmount() {
		const socket = window.socket;
		socket.off('screen-leaderboard', this.screenLeaderboard);
		socket.off('question', this.setQuestion);
		socket.off('next-question', this.preloadQuestion);
		socket.off('step-question', this.stepQuestion);
		socket.off('step-count-down', this.stepCountDown);
		socket.off('step-answer', this.stepAnswer);
		socket.off('step-scores', this.stepScores);
	}

	/**
	 * Preload video.
	 *
	 * @param question
	 */
	preloadQuestion(question) {
		log.debug('Next question', question);
		preloadVideo(question.videoSrc);
	}

	/**
	 * Server sends current question.
	 *
	 * @param question
	 */
	setQuestion(question) {
		log.debug('Received question', question);
		this.setState({
			question: question
		});
	}

	/**
	 * Step 1.
	 *
	 * @param question
	 */
	stepQuestion(question) {
		log.debug('Step Question', question);
		this.setStep({
			step: STEP_QUESTION,
			question: question
		});
	}

	/**
	 * Step 2.
	 *
	 * @param {Number} ttl TTL in seconds
	 */
	stepCountDown(ttl) {
		log.debug('Step Count Down', ttl);
		this.setStep({
			step: STEP_COUNT_DOWN,
			countDownTime: +ttl
		});
	}

	/**
	 * Step 3.
	 *
	 * @param question
	 */
	stepAnswer() {
		log.debug('Step Answer');
		this.setStep({
			step: STEP_ANSWER
		});
	}

	/**
	 * Step 4.
	 *
	 * @param {Array} scores
	 * @param {Number} ttl TTL in seconds
	 */
	stepScores(scores, ttl) {
		log.debug('Step Scores', scores, ttl);
		this.setState({
			screen: 'scores',
			step: STEP_SCORES,
			scores: scores,
			scoresTtl: +ttl
		});
	}

	/**
	 * Update current step and notify listeners.
	 *
	 * @param newState
	 */
	setStep(newState) {
		newState.screen = 'question';
		this.setState(newState);
	}

	/**
	 * Best players of the week.
	 *
	 * @param scores
	 * @param ttl
	 */
	screenLeaderboard(scores, ttl) {
		log.debug('Screen Leaderboard', scores, ttl);
		this.setState({
			screen: 'leaderboard',
			weeklyScores: scores,
			weeklyScoresTtl: +ttl
		});
	}

	/**
	 * Render screen.
	 *
	 * @return {*}
	 */
	render() {
		const theme = this.context;
		const Question = theme.Question;
		const Logo = theme.Logo;
		const Scores = theme.Scores;

		const classes = ['video-quiz', 'theme-' + theme.name, 'screen-' + this.state.screen];
		if (this.state.screen === 'question') {
			classes.push('step-' + this.state.step);
		}

		return <div id="video-quiz"
					className={classes.join(' ')}>

			{/*LeaderBoard*/}
			{this.state.screen === 'leaderboard' && <Leaderboard
				scores={this.state.weeklyScores}
				ttl={this.state.weeklyScoresTtl}/>}

			{/*Scores*/}
			{this.state.screen === 'scores' && <Scores
				scores={this.state.scores}
				ttl={this.state.scoresTtl}/>}

			{/*Question*/}
			{this.state.screen === 'question' && typeof this.state.question === 'object'
			&&
			<Question step={this.state.step} question={this.state.question} countDownTime={this.state.countDownTime}/>}

			{/* Footer */}
			<Logo/>
		</div>
	}
}
MainLayout.contextType = ThemeContext;
